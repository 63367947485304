@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,600);
@import url(https://s3.amazonaws.com/static.autofactpro.com/styles-qa/app.css);
html,
body,
#root {
  height: 100%;
  margin: 0;
}

.field-row {
  display: flex;
  justify-content: space-between;
}

.botton-row {
  padding-top: 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.loading-overlay {
  z-index: 999;
  position: absolute;
  background: rgba(0, 0, 0, 0.562);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.loading {
  position: relative;
  top: 30%;
}

.main-container {
  padding: 20px 45px;
  min-height: 400px;
  background: #f2f2f2;
}

.nav-item a {
  cursor: pointer;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.icon-title {
  height: 25.5px;
  margin-right: 17px;
  font-size: 21px;
  color: #0cb7ef;
}

.navbar-brand>img {
  width: 110px;
}


.nav-item a {
  cursor: pointer;
}

.footer {
  padding: 10px 20px;
  background-color: #555;
  color: #fff;
}

.filter-form-wrapper {
  padding: 20px 15px;
  background: #d5dae0;
}

.btn-see-link {
  font-size: 11px;
}

.closure-type {
  font-size: 10px;
  font-family: "Roboto";
  padding: 4px 10px;
  font-weight: bold;
  display: flex;
  width: 100px;
  justify-content: center;
}

.closure-status {
  font-size: 10px;
  font-family: "Roboto";
  padding: 4px 6px;
  font-weight: bold;
  display: flex;
  width: 100px;
  justify-content: center;
}

.table-text {
  font-size: 13px;
  font-family: Roboto;
  color: #6a6a6a !important;
  justify-content: center;
}

.bg-paid {
  color: #fff;
  background-color: #acc4d6;
}

.bg-pending {
  color: #fff;
  background-color: #567d9b;
  border-color: #567d9b;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 3px 5px rgba(0, 0, 0, 0.125);
}

.bg-to-confirm {
  color: #fff;
  background-color: burlywood;
}

.bg-created {
  color: #fff;
  background-color: #ffa401;
}

.bg-155892 {
  color: #fff;
  background-color: #155892;
}

.btn-outline-detail {
  border: 1px solid #567d9b;
  color: #567d9b;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
  padding: 5px 10px;
}

.btn-outline-detail:hover {
  background-color: #acc4d6;
  color: #fff;
}

.btn-fill-detail {
  border: 1px solid #567d9b;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 10px;
  background-color: #1e3c5a;
}

.btn-fill-detail:hover {
  background-color: #567d9b;
  color: #fff;
}

.btn-oc {
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
  padding: 4px 10px;
}

.btn-oc:hover {
  opacity: 0.5;
}

.btn-bg-blue-white {
  background-color: #4782B5;
  color: #fff;
}

.btn-bg-blue-white:hover {
  color: #fff;
}

.btn-bg-white-blue {
  background-color: #fff;
  color: #4782B5;
  border: 1px solid #4782B5;
}

.btn-bg-white-blue:hover {
  color: #4782B5;
}

.btn-bg-white-orange {
  background-color: #fff;
  color: #FFA401;
  border: 1px solid #FFA401;
}

.btn-bg-white-orange:hover {
  color: #FFA401
}

.btn-bg-orange-white {
  background-color: #FFA401;
  color: #fff;
  border: 1px solid #FFA401;
}

.btn-bg-orange-white:hover {
  color: #fff
}

.btn-actions {
  font-size: 14px;
  padding: 5px 50px;
}

.btn-actions:not(:disabled):hover {
  opacity: 0.7;
}

.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word;
  color: #155892 !important;
  font-size: 14px;
  font-family: Roboto;
  font-weight: bold;
}

.ReactTable .rt-noData {
  top: 78% !important;
  padding: 13px 20px;
}

.collapse-filters {
  padding-top: 0px;
}

.filter-button {
  text-align: right;
}

.DayPickerInput {
  width: 100%;
}

.invoice-info {
  font-size: 1.3rem;
}

.bank-account-fund .label {
  color: #FFA500;
  font-size: 14px
}

.bank-account-fund .text-primary-dark {
  font-size: 20px;
  font-family: Roboto, Light;
}

.bank-account-fund label {
  color: #567D9B;
  font-weight: bold;
}

.bank-account-fund select {
  color: #7994AB;
  background: #F2F2F2;
  border: 1px solid #D8D8D8;
}

.bank-account-fund {
  border-left: 4px solid #FFA500;
  background: #fff;
}

/*new design*/
.tabs-ligth .nav-item {
  background: #EBEBEB;
  margin-right: 5px;
}

.tabs-ligth .nav-item .tab-title {
  color: #7994AB;
  font-size: 14px;
  font-family: Roboto, Medium;
}

.tabs-ligth .nav-link {
  text-transform: none;
}

.tabs-ligth .nav-item .tab-subtitle {
  color: #7994AB;
  font-size: 20px;
  font-family: Roboto, Light;
}

.tabs-ligth .nav-link.active,
.tabs-ligth .nav-link:hover {
  border-top: 4px solid #FF562E;
  background-color: #E2E7EA;
}

.tabs-ligth .nav-link.active .tab-title,
.tabs-ligth .nav-link:hover .tab-title {
  color: #FF562E;
}

.tabs-ligth .nav-link.active .tab-subtitle,
.tabs-ligth .nav-link:hover .tab-subtitle {
  color: #155892;
}

.filter-form-wrapper {
  background-color: #E2E7EA;
}

.filter-form-wrapper *::placeholder {
  color: #93A3AF;
  font-family: Roboto, Regular;
  font-size: 14px;
  opacity: 1;
}

.header-opacity {
  color: #ADADAD !important;
}

.custom-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 2px solid #D5DAE0;
}

/* On mouse-over, add a grey background color */
.custom-check:hover input~.checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-check input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #155892;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.text-green-darker {
  color: #96B182 !important;
}

.btn-outline-primary-darker img.excelIcon {
  filter: invert(22%) sepia(6%) saturate(5490%)hue-rotate(160deg) brightness(50%) contrast(92%);
}

.btn-outline-primary-darker:enabled:hover img.excelIcon {
  filter: invert(0%) sepia(0%) saturate(0%)hue-rotate(0deg) brightness(100%) contrast(1000%)
}

/*upload OC modal styles*/
.modal-title {
  width: 100%
}

.cobranza-modal .header-label {
  font-family: Raleway, sans-serif;
  font-size: 16px;
}

.cobranza-modal .header-icon {
  font-size: 22px;
  margin-right: 15px;
}

.cobranza-modal .modal-title {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.cobranza-modal .modal-title .close {
  text-shadow: none;
  opacity: 1;
  color: #fff;
}

.cobranza-modal .modal-body,
.cobranza-modal .modal-footer {
  font-size: 14px;
  border-top: none;
}

.cobranza-modal .modal-footer {
  padding-bottom: 2rem;
  padding-top: 0
}

.cobranza-modal .body-title {
  font-family: Roboto, Regular, sans-serif;
  color: #155892;
}

.cobranza-modal .body-label {
  color: #7E7E7E;
  font-family: Roboto, Regular, sans-serif;
}

.cobranza-modal.draft .body-label {
  font-size: 1rem;
}

.cobranza-modal .body-field {
  border: 1px solid #CAD0D5;
}

.cobranza-modal .input-group-text {
  color: #7E8F9A;
  background-color: #D6DFE6;
  font-size: 15px !important;
}

.cobranza-modal .disabled-input {
  padding: 7px 10px;
  font-size: 14px;
}

.btn-input-upload {
  color: #fff;
  background-color: #0CB7EF;
  padding: 3px 14px 3px 10px;
  border-radius: 0 5px 5px 0;
  font-weight: bold;
  font-size: 14px;
  height: 28px;
}

.btn-input-upload:hover {
  opacity: 0.5;
  color: #fff
}

.btn-notified-payment.invoices {
  font-weight: bold;
}

.btn-payment.invoices {
  color: #FFA401;
  font-weight: bold;
  border-color: #FFA401;
}

.btn-notified-payment.closures {
  font-weight: bold;
}

.notified-loading-div {
  color: #FFA401;
  width: 160px;
  text-align: center
}

.invoice-center-billing,
.invoice-center-closure {
}

.invoice-center-closure {
  top: 17.7em;
}

.invoice-center-billing.content-buttons, 
.invoice-center-closure.content-buttons {
  text-align: right;
  margin-bottom: 2em;
}

.invoice-center-billing.content-buttons button, 
.invoice-center-closure.content-buttons button {
  margin-right: 10px;
}

.payment-success .main-container,
.payment .main-container {
  padding: 20px 45px;
  min-height: 400px;
  background: white;
}

.payment .main-container {
  font-size: 14px;
}

.payment-success .main-container .text-title {
  margin-bottom: 15px;
  font-size: 16px;
}

.payment-success .main-container .text {
  font-family: Roboto, Regular, sans-serif;
  color: #6a6a6a;
  font-size: 14px;
}

.payment .total {
  font-size: 16px;
}

.payment-success .main-container .back,
.payment .main-container .pay {
  margin-top: 100px;
  font-size: 16px;
  font-family: Raleway, sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: white
}

.payment .main-container .pay {
  margin-top: 10px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}



.payment-method {
  width: 100%;
  min-height: 160px;
  position: relative;
  display: inline-block;
}

.payment-method .method {
  background: #fff;
  padding: 6px;
  height: 160px;
  width: 168px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #dedede;
  margin-right: 25px;
  float: left;
  margin-bottom: 25px;
  cursor: pointer;
}

.payment-method .method label {
  background: #f7f7f7;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.payment-method .method input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.payment-method .method input:checked ~ .checkmark {
  background-color: #0cb7ef;
  border-color: #0cb7ef;
} 

.payment-method .method .checkmark {
  cursor: pointer;
  position: relative;
  top: 15px;
  left: 0;
  height: 32px;
  margin-bottom: 26px;
  width: 32px;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  background-color: #fff;
  display: block;
  margin-left: calc(50% - 16px);
  color: #fff;
  text-align: center;
  font-size: 19px;
} 

.tooltip > .tooltip-inner {
  padding: 6px 8px;
  max-width: 192px;
}
.tooltip__content, .tooltip__content * {
  text-align: left;
}
.tooltip__content_list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tooltip__content_first-line {
  color: white;
  line-height: 20px;
}
.tooltip__content_second-line {
  color: #adb2b7;
  line-height: 16px;
}

